.mainContent {
    min-height: 100%;
  }
  body {
    display: flex;
    flex-direction: column;
  }
  .content {
    flex: 1 0 auto;
  }
  .row-centered {
    text-align:center;
}
.col-centered {
    display:inline-block;
    float:none;
    /* reset the text-align */
    text-align:left;
    /* inline-block space fix */
    margin-right:-4px;
    align-items: center;
    background-color: #ccc;
    border: 1px solid #ddd;
}
.jumbo2{
  background: linear-gradient(
      rgba(66, 84, 81, 0.5), 
      rgba(43, 56, 54, 0.5)
  ), url('../assets/background2.jpg') center top no-repeat fixed ;
  background-size: cover;
  }
  .img-r {
    width: 100%;
  }

  @media only screen and (max-width: 600px) {
    .img-r {
      width: 90vw;
    }
  }