textarea{
  cursor: default;
        -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
          -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: -moz-none; /* Firefox */
              -ms-user-select: none; /* Internet Explorer/Edge */
              -o-user-select: none;
                  user-select: none;
}
textarea:after{
  content: attr(data-txt);
}
textarea:focus{
  outline: none;
}