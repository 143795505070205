  .container {
    margin-top: 20;
    position: relative;
    text-align: center;
    color: white;
  }
  .introContainer{
    width: 100%;
    height: 50%;
    
  }
  .bottom-left {
    position: absolute;
    bottom: 8px;
    left: 16px;
  }
  .containerItem{
    width: 50%;
  }
  .images{
      width: 100%;
  }
  .top-left {
    position: absolute;
    top: 8px;
    left: 16px;
  }
  
  .top-right {
    position: absolute;
    top: 8px;
    right: 16px;
  }
  
  .bottom-right {
    position: absolute;
    bottom: 8px;
    right: 16px;
  }
  
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .textItem{
    width:50%;
    
  }
  .mapItem{
    width:100%;
  }
.jumbo{
  background: linear-gradient(
      rgba(158, 156, 156, 0.5), 
      rgba(255, 255, 255, 0.5)
  ), url('../assets/about-bg.jpg') center top no-repeat fixed ;
  background-size: cover;
  z-index:-2;
  }
  .jumbo2{
    background:  url('../assets/digital-energy.jpg') center top no-repeat fixed ;
    background-size: cover;
    }
    .jumbo3{
      background:  url('../assets/digital-energy.jpg') center top no-repeat fixed ;
      background-size: cover;
      }


    @media only screen and (max-width: 400px) {
      .img-r {
        width: 90vw;
      }
    }


      .img-r {
        width: 10;
      }
